import {useEffect, useState} from 'react'
import { HStack, Text, VStack} from '@chakra-ui/react';
import axios from 'axios';
import _ from 'lodash';


export const CountDown = ({ending_date_time, style, starting_date_time}) => {



    const [countDown, setCountDown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    })
    
    let ending_date_time_ = new Date(ending_date_time)
    let starting_date_time_ = new Date(starting_date_time)
    let current_date = new Date();

    let start_point = starting_date_time_ > current_date ? starting_date_time_ : ending_date_time_


    // Update the countdown every second
    const countdownTimer = setInterval(function() {

      start_point = starting_date_time_ > current_date ? starting_date_time_ : ending_date_time_

    // Calculate the time remaining between now and the end date
    const now = new Date().getTime();
    const timeRemaining = start_point.getTime() - now;

    // If the countdown has ended, clear the interval and display a message
    if (timeRemaining < 0) {
        clearInterval(countdownTimer);
        setCountDown({
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        })
        return;
    }

    // Calculate the remaining days, hours, minutes, and seconds
    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24)) * 24;
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
    
    setCountDown({
        hours: hours+days,
        minutes,
        seconds
    })

    }, 1000); // Update the countdown every second (1000 milliseconds)


    return (
        <VStack spacing={-1.5} paddingTop={3}>
          <Text fontSize={14}>{starting_date_time_ > current_date ? 'Auction Starts In' : 'Auction Ends In'}</Text>
            <Text style={style}>
              {countDown.hours}h : {countDown.minutes}m : {countDown.seconds}s
          </Text>
        </VStack>
    )

}


export const truncateWalletAddress = (address, startChars, endChars) => {
    if(!address){
        return;
    }
    // Make sure the address is at least startChars + endChars characters long
    if (address.length <= startChars + endChars) {
      return address;
    }
  
    // Extract the first and last parts of the address
    const start = address.substr(0, startChars);
    const end = address.substr(-endChars);
  
    // Create the truncated address by concatenating the parts with an ellipsis
    return `${start}...${end}`;
  }



export const dateToString = (date, format) => {
    
    if(!date || !format){
        return;
    }

    return new Date(Date.parse(date)).toLocaleDateString('en-US', format)
}

export const isValidEthereumAddress = (address) => {
    if (address.substring(0, 2) !== "0x" || address.length !== 42) {
      // Check if it starts with '0x' and has a length of 42
      return false;
    }
  
    const regex = /^[0-9a-fA-F]+$/;
    if (!regex.test(address.substring(2))) {
      // Check if the remaining characters are hexadecimal
      return false;
    }
  
    return true;
}

export const searchQuery = (query) => {
    if (!query || query === '') {
      return Promise.resolve({
        nfts: [],
        collections: [],
        creators: []
      });
    }
  
    return new Promise((resolve, reject) => {
      _.delay(() => {
        axios
          .post(`${window.location.origin}/api/search-query`, { query })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      }, 200);
    });
};